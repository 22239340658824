import { documentLoaded } from '@/shared/dom-utils';
import {
    processCalendlyKeapTags,
} from '@/hosting/calendly-appointments/calendly-keap-tags-processing';

export async function processCalendly() {
    await documentLoaded();

    const widgetContainer = document.getElementById('calendly-container');

    if (!widgetContainer) {
        return;
    }

    const calendlyUrl = widgetContainer.getAttribute('data-url');
    const calendlyNameUrlParam = widgetContainer.getAttribute('data-name');
    const calendlyEmailUrlParam = widgetContainer.getAttribute('data-email');

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.head.appendChild(script);

    // Define possible keys for first name and last name as we do not have specific known keys when it comes to url params
    const firstNameKeys = ['firstname', 'first_name', 'fn', 'f_n', 'f_name', 'fname'];
    const lastNameKeys = ['lastname', 'last_name', 'ln', 'l_n', 'l_name', 'lname'];
    const emailKeys = ['email', 'em', 'e', '_e'];

    // Get the first name and last name from the URL parameters
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    var standardFullName = JSON.parse(sessionStorage.getItem('__ka_keap-hosting-form-state') ?? '{}')?.standard?.givenName ?? '';
    var standardEmail = JSON.parse(sessionStorage.getItem('__ka_keap-hosting-form-state') ?? '{}')?.standard?.emailAddress1 ?? '';

    // Function to find and return a value for the given keys
    // Looping through the possible keys is required because we do not have specific known key for firstname or lastname
    function findValueFromUrlParams(keys) {
        for (const key of keys) {
            for (const [param, value] of urlParams.entries()) {
                if (param.toLowerCase() === key.toLowerCase()) {
                    return value;
                }
            }
        }

        return '';
    }

    // Function to find and return a value for the given utm key
    // Looping through the possible keys is required because we do not have specific known key for utm firstname or lastname
    function findUTMValue(keys) {
        for (const key of keys) {
            for (const [param, value] of urlParams.entries()) {
                if (param.toLowerCase() === `utm_${key.toLowerCase()}`) {
                    return value;
                }
            }
        }

        return '';
    }

    // Looping through the possible keys is required because we do not have specific known key for firstname or lastname from url params
    function findValue(key) {
        let value = findValueFromUrlParams(key);

        // Get the value from the utm parameters
        if (!value) {
            value = findUTMValue(key);
        }

        return value;
    }

    function updateSessionStorage(key, value) {
        const formData = JSON.parse(sessionStorage.getItem('__ka_keap-hosting-form-state') ?? '{}');

        if (!formData.standard) {
            formData.standard = {};
        }
        formData.standard[key] = value;

        sessionStorage.setItem('__ka_keap-hosting-form-state', JSON.stringify(formData));
    }

    function getFullName(firstNameKeys, lastNameKeys) {
        const firstName = findValue(firstNameKeys);
        const lastName = findValue(lastNameKeys);

        return `${capitalize(firstName)} ${capitalize(lastName)}`.trim();
    }

    if (!standardFullName) {
        // try to get from specified Calendly name URL param
        standardFullName = urlParams.get(calendlyNameUrlParam) || getFullName(firstNameKeys, lastNameKeys);

        if (standardFullName) {
            // store full name if not empty
            // this is required since Calendly might not be on the first landing page
            updateSessionStorage('givenName', standardFullName);
        }
    }

    if (!standardEmail) {
        // try to get from specified Calendly email URL param
        standardEmail = urlParams.get(calendlyEmailUrlParam) || findValue(emailKeys);

        if (standardEmail) {
            // store email if not empty
            // this is required since Calendly might not be on the first landing page
            updateSessionStorage('emailAddress1', standardEmail);
        }
    }

    // Function to capitalize the first letter of each word
    function capitalize(name) {
        return name.replace(/\\b\\w/g, (char) => char.toUpperCase());
    }

    script.onload = () => {
        window.Calendly.initInlineWidget({
            url: calendlyUrl,
            parentElement: widgetContainer,
            prefill: {
                name: standardFullName,
                email: standardEmail,
            },
        });
    };

    processCalendlyKeapTags();

}
